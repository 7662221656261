import cn from '@/utils/cn';
import Link from 'next/link';
import { PlacesType, Tooltip } from 'react-tooltip';
export const variantStyles = {
  primary: `shadow-sm bg-primary-main font-semibold text-white hover:bg-primary-main/90 active:bg-primary-main
     active:text-white/70 dark:bg-slate-700 dark:hover:bg-slate-600 dark:active:bg-slate-700 dark:active:text-slate-100/70`,
  secondary: `bg-slate-100 font-medium text-primary-main hover:bg-slate-100/80 active:bg-slate-100 
    active:text-primary-main/60 dark:bg-slate-800/50 dark:text-slate-300 dark:hover:bg-slate-800
     dark:hover:text-slate-50 dark:active:bg-slate-800/50 dark:active:text-slate-50/70 border border-primary-main/20`,
  'secondary-green': `bg-green-50 font-medium text-green-600 hover:bg-green-50/80 active:bg-green-50 
    active:text-green-600/60 dark:bg-green-600/50 dark:text-green-300 dark:hover:bg-green-600
     dark:hover:text-green-50 dark:active:bg-green-600/50 dark:active:text-green-50/70 border border-green-500/20`,
  transparent: `bg-transparent font-medium text-slate-700 hover:bg-slate-100 active:bg-slate-100 
    active:text-slate-900/60 dark:bg-slate-800/50 dark:text-slate-300 dark:hover:bg-slate-800
     dark:hover:text-transparent dark:active:bg-slate-800/50 dark:active:text-transparent/70 border border-gray-500/20`,
  dark: `bg-slate-800 font-semibold text-slate-100 hover:bg-slate-700 active:bg-slate-800
   active:text-primary-main/70 dark:bg-slate-700 dark:hover:bg-slate-600 dark:active:bg-slate-700 
   dark:active:text-slate-100/70`,
  light: `bg-slate-50 font-medium text-slate-700 hover:bg-slate-100 active:bg-slate-100 
    active:text-slate-900/60 dark:bg-slate-800/50 dark:text-slate-300 dark:hover:bg-slate-800
     dark:hover:text-slate-50 dark:active:bg-slate-800/50 dark:active:text-slate-50/70 border border-gray-700/20`,
  none: `shadow-none bg-transparent text-gray-800 font-medium focus:outline-none hover:text-primary-main`,
  'hover-bg': `shadow-none bg-transparent text-gray-800 font-medium focus:outline-none hover:text-primary-main hover:bg-primary-main/10`,
  'hover-bg-active': 'shadow-sm bg-primary-main/10 text-primary-main',
  clickable: `shadow-none bg-transparent text-gray-800 font-medium focus:outline-none hover:bg-gray-50 hover:text-gray-900`
};
export const sizes = {
  small: 'py-0.5 px-3 text-xs',
  normal: 'py-1 px-3 text-sm',
  large: 'py-1.5 px-4 text-sm'
};
export type ButtonProps = {
  active?: boolean;
  size?: keyof typeof sizes;
  variant?: keyof typeof variantStyles;
  disabled?: boolean;
  tooltip?: {
    id: string;
    content: string;
    target?: string;
    placement?: string;
    className?: string;
    place?: PlacesType;
  };
  isProcessing?: boolean;
} & ((React.ComponentPropsWithoutRef<'button'> & {
  href?: undefined;
}) | React.ComponentPropsWithoutRef<typeof Link>);
export function Button({
  variant = 'primary',
  className,
  isProcessing,
  active,
  children,
  tooltip,
  size = 'large',
  ...props
}: ButtonProps) {
  className = cn(`text-nowrap inline-flex items-center gap-2 justify-center rounded-md py-1.5 px-3 text-sm outline-offset-2
     transition active:transition-none disabled:opacity-65   shadow-sm`, props.disabled as any ? 'cursor-default' : '', variantStyles[variant], active ? variantStyles[`${variant}-active` as typeof variant] : '', sizes[size], className);
  return typeof props.href === 'undefined' ? <button type={props.type || 'button'} className={className} disabled={isProcessing || props.disabled} data-tooltip-id={tooltip?.id} data-tooltip-content={tooltip?.content} data-tooltip-target={tooltip?.target || 'tooltip-bottom'} data-tooltip-placement={tooltip?.placement || 'bottom'} {...props}>
      {children}

      {tooltip && <Tooltip id={tooltip.id} className={cn('max-w-[120px] cursor-default', tooltip?.className)} place={tooltip?.place || 'bottom-start'} style={{
      fontSize: 11
    }} />}
    </button> : <Link className={className} {...props} data-tooltip-id={tooltip?.id} data-tooltip-content={tooltip?.content} data-tooltip-target={tooltip?.target || 'tooltip-bottom'} data-tooltip-placement={tooltip?.placement || 'bottom'}>
      {children}
      {tooltip && <Tooltip id={tooltip.id} className={cn('max-w-[120px] cursor-default rounded-xl', tooltip?.className)} place={tooltip?.place || 'bottom-start'} style={{
      fontSize: 11
    }} />}
    </Link>;
}