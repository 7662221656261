'use client';

import { inter } from '@/styles/fonts';
import { LOCALE } from '@/app.config';
import cn from '@/utils/cn';
import { useDarkMode } from 'usehooks-ts';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import Link from 'next/link';
import { Button } from '@/ui/Buttons/Button';
import Error from 'next/error';
export default function GlobalError({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  const {
    isDarkMode
  } = useDarkMode();
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error);
  }, [error]);
  return <html lang={LOCALE} className="scrollbar-primary h-full" suppressHydrationWarning={true} data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body className={cn('scrollbar-primary scroll-smooth md:scroll-auto' + isDarkMode ? 'dark' : '', `${inter.variable} font-sans`)} suppressHydrationWarning={true}>
        <div className="App-content min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <p className="text-4xl font-bold tracking-tight text-red-500 sm:text-5xl sm:tracking-tight">
                Error Occurred
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight">
                    Something went wrong!
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    Please check the URL in the address bar and try again.
                  </p>
                </div>
                <div className="mt-10 flex flex-wrap gap-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Link href="/dashboard" className="btn-primary w-auto" data-sentry-element="Link" data-sentry-source-file="global-error.tsx">
                    Go back home
                  </Link>
                  <Link href="/help-center" className="btn-secondary" data-sentry-element="Link" data-sentry-source-file="global-error.tsx">
                    Contact support
                  </Link>
                  <Button variant="secondary-green" onClick={() => reset()} className="btn-primary w-auto" data-sentry-element="Button" data-sentry-source-file="global-error.tsx">
                    Try again
                  </Button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </body>
    </html>;
}