import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export default function cn(...args: any[]) {
  const classes = args
    .map((arg) => {
      if (typeof arg === 'string') {
        return arg;
      }

      if (typeof arg === 'object') {
        return Object.entries(arg)
          .filter(([, value]) => value)
          .map(([key]) => key);
      }

      return '';
    })
    .flat();

  return clsx(twMerge(...classes));
}
